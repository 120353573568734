<template>
  <div class="common-layout">
    <el-container>
      <el-main class="main">
  <p class="responsive-text1" >
    好久不见
  </p>
  <p class="responsive-text2">
    别来无恙
  </p>
</el-main>
              <!-- <p style="color: white; font-size: 5vw; text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);margin: 0px;">
          好久不见
        </p> -->
      <!-- <el-main style="height: calc(100vh - 20px); display: flex; justify-content: center; align-items: center; overflow: hidden;">

       <pre>
        <pre style="margin: 0; font-size: 1vw; text-align: center; overflow: hidden;">
          <code style="font-weight: bold; ">
   ____                      _                  
  / __ \                    | |                 
 | |  | |_ __   ___       __| | __ _ _   _      
 | |  | | '_ \ / _ \     / _` |/ _` | | | |     
 | |__| | | | |  __/    | (_| | (_| | |_| |   _ 
  \____/|_| |_|\___|     \__,_|\__,_|\__, |  ( )
                                      __/ |  |/ 
                                     |___/      
                                                                                                                                                                                                                                                                                                                                                  
          </code>
        </pre>
        <pre style="margin: 0; font-size: 1vw; text-align: center; overflow: hidden;">
          <code style="font-weight: bold; ">                                                                                   
  _   _     _                   _ _ _      _                __ _             _ _       
 | | | |   (_)                 (_) | |    | |              / _(_)           | | |      
 | |_| |__  _ ___     __      ___| | |    | |__   ___     | |_ _ _ __   __ _| | |_   _ 
 | __| '_ \| / __|    \ \ /\ / / | | |    | '_ \ / _ \    |  _| | '_ \ / _` | | | | | |
 | |_| | | | \__ \     \ V  V /| | | |    | |_) |  __/    | | | | | | | (_| | | | |_| |
  \__|_| |_|_|___/      \_/\_/ |_|_|_|    |_.__/ \___|    |_| |_|_| |_|\__,_|_|_|\__, |
                                                                                  __/ |
                                                                                                                       |___/                                      
                                                                                                                                                                                                                                                                                                     
          </code>
        </pre>
        <pre style="margin: 0; font-size: 1vw; text-align: center; overflow: hidden;">
          <code style="font-weight: bold; ">                                                                                   
      _ _                                     _      _                                       
     | (_)                                   | |    | |                                      
   __| |_ ___  ___ _____   _____ _ __ ___  __| |    | |__  _   _      _   _  ___  _   _      
  / _` | / __|/ __/ _ \ \ / / _ \ '__/ _ \/ _` |    | '_ \| | | |    | | | |/ _ \| | | |     
 | (_| | \__ \ (_| (_) \ V /  __/ | |  __/ (_| |    | |_) | |_| |    | |_| | (_) | |_| |   _ 
  \__,_|_|___/\___\___/ \_/ \___|_|  \___|\__,_|    |_.__/ \__, |     \__, |\___/ \__,_|  (_)
                                                            __/ |      __/ |                 
                                                           |___/      |___/                  
                                                        </code>
        </pre>
       </pre>
      </el-main> -->
    </el-container>
  </div>
</template>

<style scoped>
 .responsive-text1 {
    color: white;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    margin: 0;
    margin-bottom: 10px;
    margin-bottom: 10px;
    font-size: min(11vw,120px);
  }
  .responsive-text2 {
    color: white;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    margin: 0;
    margin-bottom: 10px;
    margin-bottom: 10px;
    font-size: min(11vw,120px);
  }

/* 确保父容器不会引发水平滚动条 */
.common-layout {
  overflow-x: hidden;
}
p {
  font-family: Helvetica, "Trebuchet MS", Verdana, sans-serif;
}
.main {
    background-image: url('../assets/1.jpg');
    background-size: cover; /* 背景图片按比例填充容器 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
    background-position: center; /* 居中对齐背景图片 */
    min-height: calc(100vh - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


</style>
